/*--------------------
Footer Styles 
----------------------*/

.footer-wrapper {
  position: relative;
}

.footer-left {
  height: calc(100% + 2rem);
  margin-top: -2rem;
  background: linear-gradient(145deg, $theme-color 0%, $theme-color-3 100%);
  padding: 100px 120px;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: -15px;
  border-top-right-radius: 6px;

  @media #{$lg-layout} {
    padding: 120px 80px;
  }
  @media #{$md-layout} {
    padding: 60px 70px;
  }
  @media #{$sm-layout} {
    display: block;
    padding: 60px 30px;
    text-align: center;
    margin-right: 0;
  }

  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: url("/assets/images/pattern-1.png");
    content: "";
    z-index: 1;
    opacity: 0.5;
    border-top-right-radius: 6px;
  }

  > * {
    z-index: 5;
    position: relative;
  }

  > span {
    color: #fff;
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 4px;
    display: block;
    @media #{$large-mobile} {
      letter-spacing: 1px;
      margin-bottom: 9px;
    }
  }

  h2 {
    color: #ffffff;
    font-size: 75px;
    font-weight: 900;
    font-family: "Montserrat", sans-serif;
    line-height: 90px;

    @media #{$lg-layout} {
      font-size: 50px;
      line-height: 61px;
    }

    @media #{$sm-layout} {
      font-size: 45px;
      line-height: 51px;
    }
  }

  a {
    &.rn-button-style--2 {
      border-color: #ffffff;
      color: #ffffff;
      @media #{$sm-layout} {
        padding: 10px 23px;
      }
      @media #{$large-mobile} {
        padding: 7px 16px;
      }
      &:hover {
        background: #fff;
        border-color: #fff;
        color: $theme-color;
      }
    }
  }
}

.footer-right {
  background-image: url(/assets/images/bg/bg-image-8.jpg);
  padding: 60px 60px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-left: 120px;

  @media #{$lg-layout} {
    padding: 119px 21px;
    padding-left: 70px;
  }
  @media #{$md-layout} {
    padding: 60px 70px;
  }

  @media #{$sm-layout} {
    padding: 30px 30px 100px;
  }
  .footer-link {
    h4 {
      color: #c6c9d8;
      font-weight: 500;
      margin-bottom: 30px;
      opacity: 0.75;
      @media #{$sm-layout} {
        margin-bottom: 14px;
      }
    }
    ul {
      &.ft-link {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        li {
          a {
            color: #c6c9d8;
            font-size: 16px;
            @extend %transition;
            opacity: 0.75;
          }
          &:hover {
            a {
              color: $theme-color;
            }
          }
          & + li {
            margin-left: 23px;
            @media #{$sm-layout} {
              margin-left: 13px;
            }
          }
        }
      }
    }
  }
}

/* Footer Style Two  */
.footer-style-2 {
  p {
    color: #c6c9d8;
    font-size: 14px;
    opacity: 0.75;
  }
}

.copyright-text {
  margin-top: 50px;

  @media #{$sm-layout} {
    position: static;
    bottom: 0;
    padding-top: 33px;
    margin-top: 0;
  }

  @media #{$md-layout} {
    position: static;
    bottom: 0;
    padding-top: 33px;
  }
  p {
    color: #c6c9d8;
    font-size: 14px;
    opacity: 0.75;
  }
  h4 {
    @media #{$sm-layout} {
      font-size: 18px;
    }
  }
}
